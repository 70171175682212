import { useStaticQuery, graphql } from 'gatsby'
import { IGatsbyImageData } from 'gatsby-plugin-image'

interface Image {
  name: string
  childImageSharp: { gatsbyImageData: IGatsbyImageData }
}

interface Data {
  full: {
    nodes: Image[]
  }
  cropped: {
    nodes: Image[]
  }
}

export const useWeterVisualizations = () => {
  const data: Data = useStaticQuery<Data>(graphql`
    {
      full: allFile(
        filter: {
          extension: { eq: "jpg" }
          relativeDirectory: { eq: "weter/visualizations" }
          name: { regex: "/full/" }
        }
        sort: { fields: name }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, quality: 95)
          }
        }
      }
      cropped: allFile(
        filter: {
          extension: { eq: "jpg" }
          relativeDirectory: { eq: "weter/visualizations" }
          name: { regex: "/cropped/" }
        }
        sort: { fields: name }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, quality: 50)
          }
        }
      }
    }
  `)

  return { full: data.full.nodes, cropped: data.cropped.nodes }
}
